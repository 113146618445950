.Navigation {
  text-align: left;
  display:flex;
}

.Navigation-logo {
  animation: Navigation-logo-spin infinite 20s linear;
  height: 50px;
}

.Navigation-header {
  height: 40px;
  padding: 10px;
}

.NavBar {
  font-size: 1.5em;
}


.Navigation-intro {
  font-size: large;
}

.container {
    margin: 0 auto;
    padding: 20px 0;
    max-width: var(--max-content-width);
}

.brand {
    color: color(var(--brand-color) lightness(+5%));
    text-decoration: none;
    font-size: 1em; /* ~28px */
    margin-left: 10px;
    margin-right: 10px;
}

.brandTxt {
    margin-left: 10px;
}

@keyframes Navigation-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

