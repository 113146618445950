.header{
    height: 64px;
}

.logo {
    width: 120px;
    height: 60px;
    float: left;
}

.banner {
    width: 240px;
    height: 64px;
    color: rgba(3, 39, 238, 0.68);
    vertical-align: center;
    font-size: 1em;
    float:left;
    margin: 0;
}
